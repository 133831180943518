/**
 * @author Evgeny Khovansky <fajesu@ya.ru>
 * @copyright 2024 Vezo Studio
 * @link http://vezo-s.ru/
 *
 * Обозначения:
 * _a (all) - применяет свойства к элементу и его дочерним элементам
 * _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
 * _i (important) - применяет свойства с повышенным приоритетом
 */
html, body {
  width: 100%;
}

body {
  background: #fff;
}

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }
.semibold_i, .semibold_a_i, .semibold_a_i * { font-weight: 600 !important; }

span.required { color: #e01f26; font-weight: 700; }

.cred, .cred_h:hover { color: #e01f26; }
.cred_i, .cred_h_i:hover { color: #e01f26 !important; }

.bgcred, .bgcred_h:hover { background-color: #e01f26; }
.bgcred_i, .bgcred_h_i:hover { background-color: #e01f26 !important; }

.brcred, .brcred_h:hover { border-color: #e01f26; }
.brcred_i, .brcred_h_i:hover { border-color: #e01f26 !important; }


.link {
  color: #e01f26;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }

p { margin-bottom: 20px; }
p + p { margin-top: -10px; }


.form-block-container {
  background: #fff;
  padding: 28px 35px 35px;
}
  .form-popup-container .form-block-container {
    min-width: 420px;
    max-width: 700px;
  }

  .form-block-container > .subheader-wrapper {
    border-bottom: 2px solid #e01f26;
    padding: 0px 35px;
    margin: -12px -35px 0px;
  }

  .form-block-container .popup-success-text {
    margin-top: 20px;
  }

  .form {
    position: relative;
  }
    .form-row,
    #form-success-message {
      margin-top: 19px;
    }
      .form-label {
        color: #888;
        margin-bottom: 6px;
      }
        .form-row-checkbox .form-label {
          font-size: 14px;
          margin: 0px 0px 0px 10px;
          cursor: pointer;
        }
          .form-row-checkbox .form-label label {
            cursor: pointer;
          }

    .form-row-checkbox .form-field-wrapper {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
      .form-row-checkbox .form-field input {
        display: none;
      }
      .form-row-checkbox .form-field > span {
        content: "";
        color: transparent;
        background: #f6f6f6;
        border: 1px solid #ccc;
        border-radius: 2px;
        font-size: 0px;
        line-height: 0px;
        display: block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        -webkit-transition: background-color .3s ease 0s, border-color .3s ease 0s;
        -moz-transition: background-color .3s ease 0s, border-color .3s ease 0s;
        -o-transition: background-color .3s ease 0s, border-color .3s ease 0s;
        transition: background-color .3s ease 0s, border-color .3s ease 0s;
      }
        .form-row-checkbox .form-field input:hover + span,
        .form-row-checkbox .form-field input:checked + span {
          background: #e01f26;
          border-color: #e01f26;
        }

        .form-row-checkbox .form-field > span label {
          content: "";
          color: transparent;
          display: block;
          font-size: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto;
          cursor: pointer;
        }
          .form-row-checkbox .form-field > span label:after {
            content: "";
            display: none;
            border: solid #fff;
            border-width: 0px 2px 2px 0px;
            width: 4px;
            height: 9px;
            position: absolute;
            top: -3px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            transform: rotate(45deg);
          }
            .form-row-checkbox .form-field input:checked + span label:after {
              display: block;
            }

      .input-container {
        background: #f7f7f7;
        border: 1px solid #eee;
        border-radius: 2px;
        padding: 8px 13px 7px;
        position: relative;
        z-index: 1;
      }
        .input-container.error {
          border-color: #e01f26;
          border-radius: 2px 2px 0px 0px;
        }

        .input-container input,
        .input-container textarea,
        .input-container select {
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #e01f26;
          border-radius: 0px 0px 2px 2px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.3;
          padding: 3px 6px 4px;
          margin: 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 10px 0px 0px -15px;
    }
      .form-button {
        padding: 20px 0px 0px 15px;
      }


.button-style {
  color: #e01f26;
  display: inline-block;
  background: #fff;
  border: 1px solid #e01f26;
  border-radius: 2px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 12px 22px;
  cursor: pointer;
  white-space: nowrap;

  -webkit-transition: color .2s ease 0s, background-color .2s ease 0s, border-color .2s ease 0s, box-shadow .2s ease 0s;
  -moz-transition: color .2s ease 0s, background-color .2s ease 0s, border-color .2s ease 0s, box-shadow .2s ease 0s;
  -o-transition: color .2s ease 0s, background-color .2s ease 0s, border-color .2s ease 0s, box-shadow .2s ease 0s;
  transition: color .2s ease 0s, background-color .2s ease 0s, border-color .2s ease 0s, box-shadow .2s ease 0s;
}
  .button-style:active {
    box-shadow: inset 0px 3px 5px rgba(0,0,0,.125);
  }

  .button-style.active {
    color: #fff;
    background: #e01f26;
  }

  .button-style:hover {
    color: #fff;
    background: #f92027;
  }

  .button-style.green {
    color: #4fad00;
    background: #fff;
    border-color: #4fad00;
  }
  .button-style.green:hover,
  .button-style.green.active {
    color: #fff;
    background: #4fad00;
  }


ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ul.ul {
  list-style: url('/i/ul-list-style-image.svg');
  padding-left: 15px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 2px;
  }
  ul.ul li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      color: #e01f26;
      font-weight: 600;
      margin-right: 0.5em;
    }

a.zoom-image {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/image-zoom-icon.svg') 50% 50%/22px auto no-repeat #e01f26;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    margin: auto;
    z-index: 3;
    opacity: 0;

    -webkit-transition: opacity .2s ease 0s;
    -moz-transition: opacity .2s ease 0s;
    -o-transition: opacity .2s ease 0s;
    transition: opacity .2s ease 0s;
  }
    a.zoom-image:hover:before {
      opacity: 1;
    }


.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  max-width: 1470px;
  position: relative;
  padding: 0px 30px;
  margin: 0px auto;
}



/* Structure */
#structure {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
  /* Header */
  .header {
    background: #fff;
    border-bottom: 1px solid #f6f6f6;
    position: relative;
    z-index: 5;
  }
    .header-fixed {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
    }

    .header-top {
      border-bottom: 1px solid #f6f6f6;
      padding: 3px 0px;
      position: relative;
    }
      .header-top > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
        .header-top > .wrapper > div {
          margin-left: 30px;
        }
          .header-address {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            white-space: nowrap;
          }
            .header-address:before {
              content: "";
              align-self: stretch;
              background: url('/i/header-address-icon.svg') no-repeat left center/11px auto transparent;
              width: 21px;
              min-width: 21px;
            }
              .header-address > div > br {
                display: none;
              }

          .header-phones {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
            .header-phones > div:first-child {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }
              .header-phones > div:first-child:before {
                content: "";
                align-self: stretch;
                background: url('/i/header-phones-icon.svg') no-repeat left center/9px auto transparent;
                width: 20px;
              }

              .header-phones > div:first-child > div + div {
                padding-left: 15px;
              }
                .header-phones > div:first-child a {
                  color: #222;
                  font-size: 16px;
                  font-weight: 700;
                  white-space: nowrap;
                  cursor: default;
                }

            .header-phones > div + div {
              line-height: 1;
              padding-left: 10px;
            }
              .header-phones > div + div a {
                display: inline-block;
                padding: 10px 5px;
              }
                .header-phones > div + div a > span {
                  display: inline-block;
                  color: #e01f26;
                  border-bottom: 1px dashed #e01f26;
                  font-size: 13px;
                  white-space: nowrap;
                  cursor: pointer;
                }
                  .header-phones > div + div a:hover > span {
                    color: #222;
                    border-bottom-color: transparent;
                  }


          .header-search-button > span {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            padding: 10px 5px;
            cursor: pointer;
          }
            .header-search-button > span:hover {
              color: #e01f26;
            }

            .header-search-button > span:before {
              content: "";
              align-self: stretch;
              background: url('/i/search-button-icon.svg') no-repeat 50% 50%/contain transparent;
              width: 17px;
              min-height: 17px;
              margin-right: 10px;
            }
              .header-search-button > span:hover:before {
                background-image: url('/i/search-button-icon--hover.svg');
              }

          .search-form-container {
            width: 0px;
            height: 0px;
            padding: 0px !important;
            margin: 0px !important;
            position: unset;
            visibility: hidden;

            -webkit-transition: visibility 0s ease .5s;
            -moz-transition: visibility 0s ease .5s;
            -o-transition: visibility 0s ease .5s;
            transition: visibility 0s ease .5s;
          }
            .search-is-opened .search-form-container {
              visibility: visible;

              -webkit-transition: unset;
              -moz-transition: unset;
              -o-transition: unset;
              transition: unset;
            }

            .search-form-container:before {
              content: "";
              display: block;
              background: #3a3f44;
              position: fixed;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              cursor: pointer;
              opacity: 0;
              z-index: 299;

              -webkit-transition: opacity .3s ease .2s;
              -moz-transition: opacity .3s ease .2s;
              -o-transition: opacity .3s ease .2s;
              transition: opacity .3s ease .2s;
            }
              .search-is-opened .search-form-container:before {
                opacity: .7;

                -webkit-transition: opacity .3s ease 0s;
                -moz-transition: opacity .3s ease 0s;
                -o-transition: opacity .3s ease 0s;
                transition: opacity .3s ease 0s;
              }

            .search-form-container > form {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              background: #fff;
              position: absolute;
              top: -3px;
              left: 0px;
              right: 0px;
              padding: 0px 30px;
              z-index: 300;

              -webkit-transform: translateY(-100%);
              -moz-transform: translateY(-100%);
              -ms-transform: translateY(-100%);
              -o-transform: translateY(-100%);
              transform: translateY(-100%);

              -webkit-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
              -moz-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
              -o-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
              transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
            }
              .search-is-opened .search-form-container > form {
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
              }

              .search-form-container > form > .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
              }
                .search-form-container .input-container {
                  background: none;
                  border: none;
                  border-radius: 0px;
                  width: 100%;
                  padding: 0px;
                }
                  .search-form-container .input-container input {
                    font-size: 28px;
                    line-height: 4;
                  }

              .search-form-container .button-submit-container .button-style {
                font-size: 18px;
                font-weight: 600;
              }

              .search-form-container .button-close-container {
                padding-left: 25px;
              }
                .search-form-container .button-close-container span {
                  content: "";
                  color: transparent;
                  font-size: 0px;
                  background: url('/i/close-icon.svg') no-repeat 50% 50%/16px auto transparent;
                  display: block;
                  width: 40px;
                  height: 40px;
                  position: relative;
                  cursor: pointer;
                }

          .basket-line-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 5px;
            position: relative;
          }
            .basket-line-container:before {
              content: "";
              display: block;
              background: url('/i/header-basket-icon.svg') no-repeat 50% 50%/contain transparent;
              width: 18px;
              min-height: 18px;
              margin-right: 10px;
            }
              .basket-line-container:hover:before {
                background-image: url('/i/header-basket-icon--hover.svg');
              }

            .basket-line-wrapper > a,
            .basket-line-wrapper > span {
              content: "";
              color: transparent;
              font-size: 0px;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 1;
            }
            .basket-line-wrapper > a {
              cursor: pointer;
            }

            .basket-line-count {
              color: #fff;
              background: #424f60;
              border-radius: 999px;
              font-size: 11px;
              line-height: 1.1;
              min-width: 20px;
              padding: 4px 6px 4px 5px;
              text-align: center;
              white-space: nowrap;
              position: relative;
            }
              .basket-line-wrapper > a + .basket-line-count {
                background: #e01f26;
              }

              .basket-line-count span {
                display: none;
              }


    .header > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
      .header-menu-button-wrapper,
      .header-menu-checkbox {
        display: none;
      }

      .header-logo {
        flex-grow: 1;
        min-width: 200px;
        max-width: 290px;
        padding: 25px 30px 28px 0px;
      }
        .header-logo a {
          display: block;
        }
          .header-logo img {
            width: 100%;
          }


      .header-menu-container {
        flex: 1 1 auto;
        align-self: stretch;
      }
        .header-menu {
          height: 100%;
          z-index: 199;
        }
          .header-menu > ul {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: stretch;
            font-size: 14px;
            height: 100%;
          }
            .header-menu ul > li {
              position: relative;
              z-index: 0;
            }
            .header-menu > ul > li {
              flex: 0 1 auto;
              text-align: center;
              height: 100%;
            }

            .header-menu > ul > .main-item {
              display: none;
            }
            .header-menu > ul > .catalog-item {
              position: static;
            }
              .header-menu > ul > li > div {
                display: inline-block;
                height: 100%;
              }
                .header-menu > ul > li > div > .dropmarker {
                  display: none;
                }

                .header-menu > ul > li > div > div:first-child {
                  height: 100%;
                }
                  .header-menu > ul li > div a,
                  .header-menu > ul li > div span {
                    display: block;
                    position: relative;
                  }

                  .header-menu > ul li > div span {
                    cursor: default;
                  }
                  .header-menu > ul li > div a,
                  .header-menu > ul > li.catalog-item > div > div > span {
                    cursor: pointer;
                  }
                    .header-menu > ul li > div a:hover,
                    .header-menu > ul li.active > div a {
                      color: #e01f26;
                    }
                    .header-menu > ul li.active > div a {
                      cursor: default;
                    }

                  .header-menu > ul > li > div > div > a,
                  .header-menu > ul > li > div > div > span {
                    display: inline-flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.3;
                    text-align: center;
                    text-decoration: none;
                    height: 100%;
                    padding: 40px 8px;
                  }
                    .header-menu > ul > li > div > div > a:after,
                    .header-menu > ul > li > div > div > span:after {
                      content: "";
                      background: #e01f26;
                      position: absolute;
                      left: 8px;
                      bottom: 0px;
                      width: 0px;
                      height: 2px;

                      -webkit-transition: width .3s ease 0s;
                      -moz-transition: width .3s ease 0s;
                      -o-transition: width .3s ease 0s;
                      transition: width .3s ease 0s;
                    }
                      .header-menu > ul > li:hover > div > div > a:after,
                      .header-menu > ul > li:hover > div > div > span:after,
                      .header-menu > ul > li.active > div > div > a:after,
                      .header-menu > ul > li.with-active > div > div > a:after {
                        width: calc(100% - 16px);
                      }

                    .header-menu > ul > li.catalog-item > div > div > span:after {
                      display: none;
                    }

                    .header-menu > ul > li.catalog-item > div > div > span span {
                      display: block;
                      color: #fff;
                      background: #e01f26;
                      border: 1px solid #e01f26;
                      border-radius: 2px;
                      padding: 10px 15px;
                      margin: 0px -8px;
                      cursor: pointer;
                    }
                      .header-menu > ul > li.catalog-item > div > div > span:hover span {
                        background: #f92027;
                      }


              .header-menu > ul ul {
                background: #fff;
                border-top: 2px solid #e01f26;
                box-shadow: 0 6px 12px 0px rgba(0,0,0,.175);
                text-align: left;
                position: absolute;
                top: 100%;
                left: 50%;
                min-width: 100%;
                margin: -2px 0px 0px;

                visibility: hidden;
                opacity: 0;

                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);

                -webkit-transition: visibility 0s ease .3s, opacity .3s ease 0s;
                -moz-transition: visibility 0s ease .3s, opacity .3s ease 0s;
                -o-transition: visibility 0s ease .3s, opacity .3s ease 0s;
                transition: visibility 0s ease .3s, opacity .3s ease 0s;
              }
                .header-menu > ul li:hover > ul {
                  visibility: visible;
                  opacity: 1;

                  -webkit-transition: visibility 0s ease 0s, opacity .3s ease .05s;
                  -moz-transition: visibility 0s ease 0s, opacity .3s ease .05s;
                  -o-transition: visibility 0s ease 0s, opacity .3s ease .05s;
                  transition: visibility 0s ease 0s, opacity .3s ease .05s;
                }

                .header-menu > ul > .catalog-item ul {
                  border-top: none;
                  margin: 0px;
                  min-width: unset;

                  -webkit-transform: translateX(0);
                  -moz-transform: translateX(0);
                  -ms-transform: translateX(0);
                  -o-transform: translateX(0);
                  transform: translateX(0);
                }
                .header-menu > ul > .catalog-item > ul {
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                  align-content: flex-start;
                  align-items: flex-start;
                  padding: 30px 0px 0px;
                  left: 30px;
                  right: 30px;
                }
                  .header-menu > ul > .catalog-item > ul > li {
                    width: 25%;
                    padding: 0px 30px 35px 20px;
                  }

                  .header-menu > ul ul > li + li {
                    border-top: 1px solid #f0f0f0;
                  }
                  .header-menu > ul > .catalog-item ul > li + li {
                    border-top: none;
                  }

                    .header-menu > ul ul > li > div {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: stretch;
                    }
                    .header-menu > ul > .catalog-item ul > li > div {
                      padding-left: 20px;
                    }

                      .header-menu > ul ul > li > div > div:first-child {
                        width: 100%;
                      }
                        .header-menu > ul ul > li > div a,
                        .header-menu > ul ul > li > div span {
                          padding: 14px 20px 14px 18px;
                          white-space: nowrap;
                        }
                        .header-menu > ul > .catalog-item ul > li > div a,
                        .header-menu > ul > .catalog-item ul > li > div span {
                          font-size: 13px;
                          font-weight: 700;
                          padding: 4px 0px;
                          white-space: normal;
                        }

                      .header-menu > ul ul > li > div > .dropmarker {
                        color: transparent;
                        font-size: 0px;
                        background: url('/i/header-menu-dropmarker-right.svg') no-repeat 0% 50%/4px auto transparent;
                        width: 20px;
                        min-width: 20px;
                        position: relative;
                      }
                        .header-menu > ul ul > li:hover > div > .dropmarker,
                        .header-menu > ul ul > li.active > div > .dropmarker,
                        .header-menu > ul ul > li.with-active > div > .dropmarker {
                          background-image: url('/i/header-menu-dropmarker-right--hover.svg');
                        }
                        .header-menu > ul > .catalog-item ul > li > div > .dropmarker {
                          display: none;
                        }


                    .header-menu > ul ul ul {
                      top: 0px;
                      left: 100%;

                      -webkit-transform: translateX(0);
                      -moz-transform: translateX(0);
                      -ms-transform: translateX(0);
                      -o-transform: translateX(0);
                      transform: translateX(0);
                    }
                      .header-menu > ul > .catalog-item ul ul {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: flex-start;
                        align-content: flex-start;
                        align-items: flex-start;
                        background: transparent;
                        box-shadow: none;
                        position: static;
                        top: auto;
                        left: auto;
                        visibility: unset;
                        opacity: 1;

                        -webkit-transition: none;
                        -moz-transition: none;
                        -o-transition: none;
                        transition: none;
                      }
                        .header-menu > ul > .catalog-item ul ul ul {
                          display: none;
                        }
                        .header-menu > ul > .catalog-item ul ul > li > div a,
                        .header-menu > ul > .catalog-item ul ul > li > div span {
                          font-weight: 400;
                        }


  /* Content block */
  .page-top {
    background: #f9f9f9;
    padding: 22px 0 25px;
  }
    .subheader {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.3;
      padding: 10px 0px 25px;
    }
      h1.subheader {
        font-size: 28px;
        padding-top: 0px;
      }
        .page-top .subheader {
          font-size: 30px;
          padding-bottom: 15px;
        }


    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      line-height: 1.3;
      padding: 0px;
      margin: 0px 0px 0px -3px;
      position: relative;
      z-index: 4;
    }
      .breadcrumbs > li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .breadcrumbs > li:not(:last-child):after {
          content: "-";
          padding: 0px 2px;
        }
        .breadcrumbs > li:not(:last-child):after,
        .breadcrumbs > li a,
        .breadcrumbs > li span {
          color: #b6b5b5;
          font-size: 12px;
        }
        .breadcrumbs > li a,
        .breadcrumbs > li span {
          padding: 2px 3px;
        }
        .breadcrumbs > li a {
          cursor: pointer;
        }
          .breadcrumbs > li a:hover {
            color: #e01f26;
          }


  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    padding-top: 25px;
    z-index: 3;
  }
    .left-column {
      order: 1;
      min-width: 290px;
      max-width: 290px;
      padding: 5px 30px 0px 0px;
      position: relative;
      z-index: 2;
    }
      .page-top + .wrapper > .content > .left-column {
        padding-top: 0px;
      }

      .left-column > div {
        padding: 0px 0px 30px;
      }
        .left-menu > ul {
          border: solid #f2f2f2;
          border-width: 0px 1px 1px;
        }
          .left-menu ul > li {
            position: relative;
          }
            .left-menu > ul > li:hover:before {
              content: "";
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              right: -2px;
              bottom: 0px;
              z-index: 0;
            }

            .left-menu > ul > li > div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: stretch;
              border-top: 1px solid #f2f2f2;
              position: relative;
              z-index: 1;
            }
              .left-menu > ul > li:hover > div {
                background: #fff;
              }
              .left-menu > ul > li.active > div {
                background: #e01f26;
              }

              .left-menu > ul > li > div > div:first-child {
                width: 100%;
              }
                .left-menu > ul > li > div a,
                .left-menu > ul > li > div span {
                  display: block;
                  text-transform: uppercase;
                  padding: 11px 17px 13px;
                }
                .left-menu > ul > li > div span {
                  cursor: default;
                }
                .left-menu > ul > li > div a {
                  cursor: pointer;
                }
                  .left-menu > ul > li:hover > div a,
                  .left-menu > ul > li:hover > div span {
                    border-left: 3px solid #e01f26;
                    padding-left: 14px;
                  }
                  .left-menu > ul > li > div a:hover {
                    color: #e01f26;
                  }
                  .left-menu > ul > li.active > div a,
                  .left-menu > ul > li.active > div span {
                    color: #fff;
                  }

              .left-menu > ul > li > div .dropmarker {
                color: transparent;
                font-size: 0px;
                background: url('/i/left-menu-dropmarker-right.svg') no-repeat 0% 50%/auto 10px transparent;
                width: 22px;
                min-width: 22px;
                position: relative;
              }
                .left-menu > ul > li:hover > div > .dropmarker {
                  background-image: url('/i/left-menu-dropmarker-right--hover.svg');
                }
                .left-menu > ul > li.active > div > .dropmarker {
                  background-image: url('/i/left-menu-dropmarker-right--active.svg');
                }

            .left-menu > ul > li > ul {
              display: none;
              background: #fff;
              box-shadow: 0px 2px 10px 0px rgba(0,0,0,.1);
              position: absolute;
              left: 100%;
              top: 1px;
              margin-left: 1px;
              z-index: 2;
            }
              .left-menu > ul > li:hover > ul {
                display: block;
                padding: 25px 10px 10px 10px;
              }
                .left-menu > ul > li:hover > ul > li {
                  padding: 0px 20px 20px 20px;
                }
                  .left-menu > ul > li > ul > li > div .dropmarker,
                  .left-menu > ul > li > ul ul {
                    display: none;
                  }

                  .left-menu > ul > li > ul > li > div a,
                  .left-menu > ul > li > ul > li > div span {
                    font-size: 13px;
                    font-weight: 700;
                    white-space: nowrap;
                  }
                  .left-menu > ul > li > ul > li > div span {
                    cursor: default;
                  }
                  .left-menu > ul > li > ul > li > div a {
                    cursor: pointer;
                  }
                    .left-menu > ul > li > ul > li > div a:hover,
                    .left-menu > ul > li > ul > li.active > div a {
                      color: #e01f26;
                    }


    .right-column {
      order: 2;
      width: 100%;
      position: relative;
      z-index: 1;
    }
    .left-column + .right-column {
      width: calc(100% - 290px);
    }

      .main-rotator-container {
        padding: 0px 0px 30px;
        position: relative;
        z-index: 1;
      }
        .main-rotator-container > div {
          overflow: hidden;
          position: relative;
        }
          .main-rotator,
          .main-rotator .slick-track {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
          }
          .main-rotator {
            width: 100%;
            position: relative;
            z-index: 1;
          }
            .main-rotator-slide {
              display: flex !important;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
              float: none !important;
              width: 100%;
              min-width: 100%;
              max-width: 100%;
              height: auto !important;
            }
              .slick-initialized .main-rotator-slide {
                min-width: auto;
              }

              .main-rotator-slide-image,
              .main-slide-code-container {
                width: 50%;
              }
                .main-rotator-slide-image a,
                .main-rotator-slide-image span {
                  display: block;
                  background: no-repeat 50% 50%/cover #eee;
                  width: 200%;
                  max-width: 200%;
                  height: 100%;
                  position: relative;
                  z-index: 1;
                }
                .main-rotator-slide-image a {
                  cursor: pointer;
                }
                  .main-rotator-slide-image a:before,
                  .main-rotator-slide-image span:before {
                    content: "";
                    display: block;
                    height: 0px;
                    padding-top: 28%;
                  }

              .main-slide-code-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
              }
                .main-slide-code-container > div {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  width: 200%;
                  max-width: 200%;
                  padding: 50px 90px 55px;
                }
                  .main-slide-code-container > div > div {
                    position: relative;
                    z-index: 2;
                  }
                  .main-slide-code-container .slide-title {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 1.2;
                    text-shadow: 0px 1px 20px #fff;
                  }
                    .main-slide-code-container.cw .slide-title {
                      text-shadow: 0px 1px 20px #000;
                    }

                  .main-slide-code-container .slide-text {
                    font-size: 15px;
                    padding: 11px 0px 0px;
                    text-shadow: 0px 1px 10px #fff;
                  }
                    .main-slide-code-container.cw .slide-text {
                      text-shadow: 0px 1px 10px #000;
                    }

                    .main-slide-code-container .slide-text :last-child {
                      margin-bottom: 0px;
                    }

                  .main-slide-code-container .slide-link {
                    padding: 25px 0px 0px;
                  }
                    .main-slide-code-container .slide-link .button-style {
                      font-size: 14px;
                    }


          .main-rotator-container .slick-arrows {
            height: 0px;
          }
            .main-rotator-container .slick-arrows div {
              position: absolute;
              top: 0px;
              bottom: 0px;
              width: 90px;
              cursor: pointer;
              opacity: 0;
              z-index: 2;

              -webkit-transition: opacity .3s ease 0s, left .3s ease 0s, right .3s ease 0s;
              -moz-transition: opacity .3s ease 0s, left .3s ease 0s, right .3s ease 0s;
              -o-transition: opacity .3s ease 0s, left .3s ease 0s, right .3s ease 0s;
              transition: opacity .3s ease 0s, left .3s ease 0s, right .3s ease 0s;
            }
              .main-rotator-container .slick-arrows div:before {
                content: "";
                background: no-repeat 50% 50%/12px auto #fff;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                width: 42px;
                height: 42px;
                margin: auto;

                -webkit-transition: background-color .2s ease 0s;
                -moz-transition: background-color .2s ease 0s;
                -o-transition: background-color .2s ease 0s;
                transition: background-color .2s ease 0s;
              }
                .main-rotator-container .slick-arrows div:hover:before {
                  background-color: #e01f26;
                }

            .main-rotator-container .slick-arrows .slick-prev {
              left: -90px;
            }
              .main-rotator-container > div:hover .slick-arrows .slick-prev {
                left: 0px;
                opacity: 1;
              }

              .main-rotator-container .slick-arrows .slick-prev:before {
                background-image: url('/i/rotator-arrow-left.svg');
              }
                .main-rotator-container .slick-arrows .slick-prev:hover:before {
                  background-image: url('/i/rotator-arrow-left--white.svg');
                }
            .main-rotator-container .slick-arrows .slick-next {
              right: -90px;
            }
              .main-rotator-container > div:hover .slick-arrows .slick-next {
                right: 0px;
                opacity: 1;
              }

              .main-rotator-container .slick-arrows .slick-next:before {
                background-image: url('/i/rotator-arrow-right.svg');
              }
                .main-rotator-container .slick-arrows .slick-next:hover:before {
                  background-image: url('/i/rotator-arrow-right--white.svg');
                }


          .main-rotator-container .slick-dots {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            height: 0px;
          }
            .main-rotator-container .slick-dots ul {
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
              align-content: flex-start;
              align-items: flex-start;
              min-height: 48px;
            }
              .main-rotator-container .slick-dots li {
                padding: 8px 0px 0px 8px;
              }
                .main-rotator-container .slick-dots li div {
                  background: transparent;
                  border: 2px solid #fff;
                  border-radius: 50%;
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
                  position: relative;
                  z-index: 3;
                }
                  .main-rotator-container .slick-dots li div:hover,
                  .main-rotator-container .slick-dots li.slick-active div {
                    background: #fff;
                  }


      .advantages-block {
        padding: 0px 0px 30px;
      }
        .advantages-block > div {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;
          padding: 0px 0px 35px;
          margin: -20px 0px 0px;
        }
          .advantages-block > div > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 25%;
            padding: 25px 15px 0px 30px;
          }

        .advantages-block div > .image {
          display: block;
          background: no-repeat 50% 50%/contain transparent;
          min-width: 32.87671233%;
        }
          .advantages-block div > .image:before {
            content: "";
            display: block;
            height: 0px;
            padding-top: 85.33333333%;
          }

        .advantages-block div > .text {
          font-size: 14px;
          line-height: 1.3;
          padding: 0px 0px 3px 18px;
        }


      .list-view {
        padding: 0px 0px 30px;
        margin: 1px 0px 0px 1px;
      }
        .list-view-wrapper {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: stretch;
          position: relative;
        }
          .list-view-item {
            width: calc(20% + 1px);
            height: auto !important;
            padding: 21px 12px;
            margin: -1px 0px 0px -1px;
            position: relative;
            z-index: 0;
          }
            .list-view-item:hover {
              z-index: 1;
            }

            .list-view-item:before {
              content: "";
              background: #fff;
              border: 1px solid #f2f2f2;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 1;

              -webkit-transition: box-shadow .2s ease-out 0s;
              -moz-transition: box-shadow .2s ease-out 0s;
              -o-transition: box-shadow .2s ease-out 0s;
              transition: box-shadow .2s ease-out 0s;
            }
              .list-view-item:hover:before {
                box-shadow: 0px 0px 20px 0px rgba(0,0,0,.15);
              }
              .product-list .list-view-item:hover:before {
                bottom: -45px;
              }

            .list-view-item > div {
              position: relative;
              z-index: 2;
            }

            .list-view-item > div,
            .list-view-image + div {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: stretch;
              height: 100%;
            }
              .list-view-image {
                padding: 0px 10px 15px;
              }
                .list-view-image a,
                .list-view-image span {
                  content: "";
                  background: no-repeat 50% 50%/cover #eee;
                  display: block;
                  font-size: 0px;
                  position: relative;
                  overflow: hidden;
                }
                .list-view-image a {
                  cursor: pointer;
                }
                .list-view-image a:before,
                .list-view-image span:before {
                  content: "";
                  display: block;
                  height: 0px;
                  padding-top: 82%;
                }
                .list-view-image a:after,
                .list-view-image span:after {
                  content: "";
                  background: url('/i/product-image-gradient.svg') repeat-y 0% 0%/contain transparent;
                  display: block;
                  position: absolute;
                  top: 0px;
                  left: -45%;
                  width: 25%;
                  bottom: 0px;
                  opacity: 0;
                  z-index: 1;

                  -webkit-transform: skewX(-25deg);
                  -moz-transform: skewX(-25deg);
                  -ms-transform: skewX(-25deg);
                  -o-transform: skewX(-25deg);
                  transform: skewX(-25deg);

                  -webkit-transition: unset;
                  -moz-transition: unset;
                  -o-transition: unset;
                  transition: unset;
                }
                  .list-view-image a:hover:after,
                  .list-view-image span:hover:after {
                    left: 125%;
                    opacity: 1;

                    -webkit-transition: left .4s ease 0s;
                    -moz-transition: left .4s ease 0s;
                    -o-transition: left .4s ease 0s;
                    transition: left .4s ease 0s;
                  }


              .list-view-name {
                text-align: center;
                padding: 0px 10px;
              }
                .list-view-name a,
                .list-view-name span {
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 1.2;
                  text-decoration: none;
                }
                .list-view-name a {
                  cursor: pointer;
                }
                  .list-view-name a:hover,
                  .list-view-image:hover + div .list-view-name a {
                    color: #e01f26;
                  }


              .list-view-notice {
                color: #666;
                font-size: 12px;
                line-height: 1.2;
                text-align: center;
                padding-top: 4px;
              }


              .product-labels {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;
                margin: -6px 0px 0px -6px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                height: 0px;
                z-index: 3;
              }
                .list-view .product-labels {
                  left: 10px;
                  right: 10px;
                }

                .product-labels span {
                  color: #fff;
                  border-radius: 2px;
                  font-size: 10px;
                  line-height: 1.1;
                  letter-spacing: 0.5px;
                  text-transform: uppercase;
                  padding: 5px 10px;
                  margin: 6px 0px 0px 6px;
                }
                .product-labels .spec {
                  background: #2992d9;
                }
                .product-labels .recommend {
                  background: #893ca9;
                }
                .product-labels .discount {
                  color: #1d2029;
                  background: #ffc438;
                }
                .product-labels .novelty {
                  background: #4fad00;
                }


              .product-list-dump,
              .product-card-dump {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: #666;
                font-size: 12px;
                padding: 10px 0px 0px;
              }
                .product-list-dump:before,
                .product-card-dump:before {
                  content: "";
                  background: url('/i/tick-icon--green.svg') no-repeat 50% 50%/contain transparent;
                  width: 11px;
                  min-width: 11px;
                  min-height: 12px;
                }
                .product-list-dump span,
                .product-card-dump span {
                  margin: 0px 0px 0px 6px;
                }

              .product-list-price {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                padding: 8px 0px 0px;
              }
                .product-list-price span:first-child {
                  display: none;
                }

                .product-list-addit-price {
                  color: #666;
                  font-size: 13px;
                  font-weight: 600;
                  margin-top: 2px;
                }


              .product-list-buttons {
                display: none;
                height: 0px;
              }
                .list-view-item:hover .product-list-buttons {
                  display: block;
                }

                .product-list-buttons > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: stretch;
                  margin-left: -12px;
                  height: 45px;
                }

                  .product-list-buttons > div > div {
                    flex: 1 1 auto;
                  }

                  .product-item-button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 6px;
                    margin: 12px 0px 0px 12px;
                    order: 2;
                  }
                    .product-item-button.already-in-basket {
                      flex-grow: 1;
                      color: #fff;
                      background: #4fad00;
                      border-color: #4fad00;
                    }
                      .product-item-button.already-in-basket:before {
                        content: "";
                        background: url('/i/tick-icon--white.svg') no-repeat 50% 50%/contain transparent;
                        width: 11px;
                        min-width: 11px;
                        min-height: 12px;
                        margin-right: 6px;
                      }

                    .product-item-button-callback {
                      flex-grow: 1;
                    }


                  .product-amount-container {
                    order: 1;
                    padding: 12px 0px 0px 12px;
                  }
                    .product-item-button.already-in-basket + .product-amount-container {
                      display: none;
                    }

                    .product-amount-wrapper {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: stretch;
                      background: #f3f3f5;
                      width: 100%;
                      height: 100%;
                    }
                      .product-amount-wrapper .ui-spinner-button {
                        content: "";
                        font-size: 0px;
                        background: no-repeat 50% 50%/10px transparent;
                        width: 24px;
                        min-width: 24px;
                        cursor: pointer;
                      }
                      .product-amount-wrapper .ui-spinner-down {
                        background-image: url('/i/product-amount-input-minus.svg');
                        order: 1;
                      }
                      .product-amount-wrapper .ui-spinner-up {
                        background-image: url('/i/product-amount-input-plus.svg');
                        order: 3;
                      }

                      .product-amount-wrapper > div:first-child {
                        width: 100%;
                        order: 2;
                      }
                        .product-amount-input {
                          background: transparent;
                          font-size: 1em;
                          font-weight: 400;
                          line-height: 1.3;
                          text-align: center;
                          width: 100%;
                          min-width: 100%;
                          max-width: 100%;
                          height: 100%;
                          border: 0px;
                          padding: 0px;
                          margin: 0px;
                          order: 2;
                        }


      .list-view-vitrine {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: stretch;
        padding: 0px 0px 30px;
        margin: -20px 0px 0px -20px;
        position: relative;
      }
        .list-view-vitrine-item {
          width: 25%;
          padding: 20px 0px 0px 20px;
          position: relative;
        }
          .list-view-vitrine-item:nth-child(12n+1),
          .list-view-vitrine-item:nth-child(12n+5),
          .list-view-vitrine-item:nth-child(12n+9),
          .list-view-vitrine-item:nth-child(12n+11) {
            width: 50%;
          }

          .list-view-vitrine-item > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            position: relative;
            overflow: hidden;
          }
            .list-view-vitrine-name,
            .list-view-vitrine-image {
              width: 50%;
            }

            .list-view-vitrine-name {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;
            }
              .list-view-vitrine-name > div {
                width: 200%;
                padding: 20px 35px 20px 20px;
              }
                .list-view-vitrine-name > div > a,
                .list-view-vitrine-name > div > span {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 1.4;
                  color: #fff;
                  background: #e01f26;
                  text-decoration: none;
                  padding: 4px 0px 5px;
                  position: relative;
                  z-index: 2;
                }
                  .list-view-vitrine-name > div > a > span,
                  .list-view-vitrine-name > div > span > span {
                    background: #e01f26;
                    left: 17px;
                    padding: 4px 0px 5px;
                    position: relative;
                  }
                    .list-view-vitrine-name > div > a > span > span,
                    .list-view-vitrine-name > div > span > span > span {
                      left: -9px;
                      padding: 4px 0px 5px;
                      position: relative;
                    }



            .list-view-vitrine-item:nth-child(12n+1) .list-view-vitrine-image,
            .list-view-vitrine-item:nth-child(12n+5) .list-view-vitrine-image,
            .list-view-vitrine-item:nth-child(12n+9) .list-view-vitrine-image,
            .list-view-vitrine-item:nth-child(12n+11) .list-view-vitrine-image {
              padding: 0px 0px 0px 10px;
            }

              .list-view-vitrine-image:before {
                content: "";
                display: block;
                height: 0px;
                padding-top: 160%;
              }
                .list-view-vitrine-item:nth-child(12n+1) .list-view-vitrine-image:before,
                .list-view-vitrine-item:nth-child(12n+5) .list-view-vitrine-image:before,
                .list-view-vitrine-item:nth-child(12n+9) .list-view-vitrine-image:before,
                .list-view-vitrine-item:nth-child(12n+11) .list-view-vitrine-image:before {
                  padding-top: 80%;
                }

              .list-view-vitrine-image a,
              .list-view-vitrine-image span {
                content: "";
                background: no-repeat 50% 50%/cover #eee;
                display: block;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }
              .list-view-vitrine-image a {
                cursor: pointer;

                -webkit-transition: -webkit-transform .4s ease 0s, -moz-transform .4s ease 0s, -ms-transform .4s ease 0s, -o-transform .4s ease 0s, transform .4s ease 0s;
                -moz-transition: -webkit-transform .4s ease 0s, -moz-transform .4s ease 0s, -ms-transform .4s ease 0s, -o-transform .4s ease 0s, transform .4s ease 0s;
                -o-transition: -webkit-transform .4s ease 0s, -moz-transform .4s ease 0s, -ms-transform .4s ease 0s, -o-transform .4s ease 0s, transform .4s ease 0s;
                transition: -webkit-transform .4s ease 0s, -moz-transform .4s ease 0s, -ms-transform .4s ease 0s, -o-transform .4s ease 0s, transform .4s ease 0s;
              }
                .list-view-vitrine-image:hover a,
                .list-view-vitrine-name:hover + .list-view-vitrine-image a {
                  -webkit-transform: scale(1.2);
                  -moz-transform: scale(1.2);
                  -ms-transform: scale(1.2);
                  -o-transform: scale(1.2);
                  transform: scale(1.2);
                }

                .list-view-vitrine-image a:after {
                  content: "";
                  background: #000;
                  display: block;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  opacity: 0;

                  -webkit-transition: opacity .3s ease 0s;
                  -moz-transition: opacity .3s ease 0s;
                  -o-transition: opacity .3s ease 0s;
                  transition: opacity .3s ease 0s;
                }
                  .list-view-vitrine-image:hover a:after,
                  .list-view-vitrine-name:hover + .list-view-vitrine-image a:after {
                    opacity: .5;
                  }


      .pagination-container {
        padding: 0px 0px 25px;
      }
        .list-view-wrapper + div .pagination-container {
          padding: 25px 0px 0px;
        }
        .pagination-container ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: stretch;
          white-space: nowrap;
          margin: -5px 0px 0px -5px;
        }
          .pagination-container li {
            font-size: 14px;
            font-weight: 700;
            line-height: 1;
            padding: 5px 0px 0px 5px;
          }
            .pagination-container li.first,
            .pagination-container li.last {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: baseline;
            }
              .pagination-container li.first:after,
              .pagination-container li.last:before {
                content: "\2026";
              }
              .pagination-container li.first:before {
                margin-left: 5px;
              }
              .pagination-container li.last:before {
                margin-right: 5px;
              }


            .pagination-container li a {
              color: #222;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 32px;
              min-width: 32px;
              min-height: 32px;
            }
            .pagination-container li a {
              cursor: pointer;
            }
              .pagination-container li a:hover {
                color: #e01f26;
              }

            .pagination-container li.active a {
              color: #fff;
              background: #e01f26;
              border-radius: 2px;
            }


      .catalog-section-list .list-view-item {
        width: calc(50% + 1px);
        padding: 31px;
      }
        .catalog-section-list .list-view-item > div {
          flex-direction: row;
          align-items: flex-start;
        }
          .catalog-section-list .list-view-image {
            width: 35%;
            padding: 0px 28px 0px 0px;
          }

          .catalog-section-list .list-view-image + div {
            width: 65%;
          }
            .catalog-section-list .list-view-name {
              padding: 0px 0px 10px;
              text-align: left;
            }
              .catalog-section-list .list-view-name a {
                font-size: 16px;
                text-transform: uppercase;
              }

            .catalog-section-list .list-view-name + div {
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              align-content: flex-start;
              align-items: flex-start;
              margin: -2px 0px 0px -10px;
            }
              .catalog-section-list .list-view-name + div > div {
                padding: 2px 0px 0px 10px;
              }

              .catalog-section-list .list-view-name + div a {
                color: #222;
                text-decoration: none;
                cursor: pointer;
              }
                .catalog-section-list .list-view-name + div a:hover {
                  color: #e01f26;
                }

                .catalog-section-list .list-view-name + div a span {
                  color: #707070;
                  cursor: default;
                }


      .vitrine-tabs,
      .vitrine-tabs .tabs {
        position: relative;
      }
        .vitrine-tabs .tabs-nav {
          padding-bottom: 25px;
          margin-left: -28px;
          position: relative;
          z-index: 1;
        }
          .vitrine-tabs .tabs-nav ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            margin: -12px auto 0px;
          }
            .vitrine-tabs .tabs-nav li {
              padding: 12px 0px 0px 28px;
            }
              .vitrine-tabs .tabs-nav li a {
                color: #e01f26;
                display: block;
                border-bottom: 2px solid transparent;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.1;
                padding: 0px 0px 4px;
                white-space: nowrap;
                cursor: pointer;
              }
              .vitrine-tabs:not(.initialized) .tabs-nav li:first-child a,
              .vitrine-tabs .tabs-nav li.active a,
              .vitrine-tabs .tabs-nav li a.active {
                color: #222;
                border-bottom-color: #e01f26;
              }

        .vitrine-tabs .tabs > div {
          display: none;
        }
          .vitrine-tabs:not(.initialized) .tabs > div:first-child,
          .vitrine-tabs .tabs > div.active {
            display: block;
          }


    .product-card-info-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: 0px 0px 30px;
    }
      .product-card-images {
        border: solid #f2f2f2;
        border-width: 1px 0px 1px 1px;
        width: 50%;
        padding: 30px 45px 30px 40px;
      }
        .product-labels-container {
          height: 0px;
          position: relative;
        }
          .product-labels-container .product-labels span {
            font-size: 13px;
          }

          .product-card-images-rotator {
            position: relative;
          }
            .product-card-images-rotator:before {
              content: "";
              display: block;
              height: 0px;
              padding-top: 75%;
            }
              .product-card-image {
                position: relative;
                z-index: 1;
              }
                .product-card-image + .product-card-image {
                  display: none;
                }

                .product-card-images-rotator > .product-card-image {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                }
                  .product-card-images-rotator > .product-card-image:first-child {
                    z-index: 2;
                  }

                .product-card-image a {
                  display: block !important;
                  background: no-repeat 50% 50%/contain transparent;
                  position: relative;
                }
                  .product-card-image a:after {
                    content: "";
                    display: block;
                    height: 0px;
                    padding-top: 75%;
                  }


      .product-card-info {
        border: solid #f2f2f2;
        border-width: 1px 1px 1px 0px;
        width: 50%;
        padding: 35px 40px 40px 0px;
      }
        .product-card-info > div {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: stretch;
        }
          .product-card-properties,
          .product-card-properties + div {
            padding-bottom: 30px;
          }

          .product-card-properties {
            padding-right: 25px;
          }
            .product-card-properties .title {
              color: #444;
              font-size: 14px;
              font-weight: 600;
            }
            .product-card-properties .title ~ div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              font-size: 13px;
              padding: 9px 0px 0px;
              position: relative;
            }
              .product-card-properties .title ~ div:before {
                content: "";
                display: block;
                border-bottom: 1px dotted #d0d0d0;
                height: 5px;
                position: absolute;
                top: 50%;
                left: 2px;
                right: 2px;
                z-index: 0;
              }

              .product-card-properties .title ~ div span:first-child {
                color: #888;
                background: #fff;
                white-space: nowrap;
                padding-right: 10px;
                position: relative;
                z-index: 1;
              }
              .product-card-properties .title ~ div span + span {
                background: #fff;
                padding-left: 10px;
                position: relative;
                z-index: 1;
              }


          .product-card-properties + div {
            line-height: 1.3;
          }
            .product-card-price {
              font-size: 20px;
              vertical-align: baseline;
            }
              .product-card-price span {
                vertical-align: baseline;
              }
              .product-card-price span + span {
                font-size: 26px;
                font-weight: 700;
              }

            .product-card-addit-price {
              color: #888;
              font-size: 15px;
              vertical-align: baseline;
              padding: 5px 0px 0px;
            }
              .product-card-addit-price span {
                vertical-align: baseline;
              }
              .product-card-addit-price span + span {
                font-size: 18px;
                font-weight: 600;
              }


          .product-card-dump {
            font-size: 13px !important;
            justify-content: flex-start !important;
            padding: 22px 0px 0px !important;
          }
            .product-card-dump:before {
              min-width: 13px !important;
            }


          .product-card-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            padding: 13px 0px 0px;
            margin-left: -12px;
          }
            .product-card-buttons .to-basket {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              order: 2;
              margin: 12px 0px 0px 12px;
            }
              .product-card-buttons .already-in-basket {
                color: #fff;
                background: #4fad00;
                border-color: #4fad00;
              }
                .product-card-buttons .already-in-basket:before {
                  content: "";
                  background: url('/i/tick-icon--white.svg') no-repeat 50% 50%/contain transparent;
                  width: 11px;
                  min-width: 11px;
                  min-height: 12px;
                  margin-right: 6px;
                }


            .product-card-amount-container {
              flex: 1 1 auto;
              order: 1;
              max-width: 130px;
              padding: 12px 0px 0px 12px;
            }
              .product-card-buttons .already-in-basket + .product-card-amount-container {
                display: none;
              }

              .product-card-amount-container .product-amount-wrapper > a {
                width: 34px;
                min-width: 34px;
              }


        .product-card-info-notice {
          color: #7d7d7d;
          font-size: 12px;
          border-top: 1px solid #f2f2f2;
          width: 100%;
          padding: 30px 0px 0px;
        }



    .basket-products {
      padding: 1px 0px 25px;
      width: 100%;
    }
      .basket-product-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        border: 1px solid #e8e8e8;
        margin: -1px 0px 0px;
        position: relative;

        -webkit-transition: box-shadow .2s ease 0s;
        -moz-transition: box-shadow .2s ease 0s;
        -o-transition: box-shadow .2s ease 0s;
        transition: box-shadow .2s ease 0s;
      }
        .basket-product-item:hover {
          box-shadow: 0 2px 18px rgba(0,0,0,.12);
          z-index: 1;
        }

        .basket-product-item > div {
          display: flex;
          flex-direction: row;
        }
        .basket-product-item > div:first-child {
          justify-content: flex-start;
          align-items: flex-start;
          flex: 1 0 0;
          padding: 25px 0px 25px 25px;
        }
          .basket-product-item .list-view-item-image {
            width: 14%;
            min-width: 14%;
            margin: 0px;
          }
            .basket-product-item .list-view-item-image a {
              display: block;
              background: no-repeat 50% 50%/contain transparent;
              height: 0;
              padding: 75% 0 0;
              position: relative;
              z-index: 1;
            }

          .basket-product-item .list-view-item-text {
            background: none;
            height: auto;
            padding: 10px 25px 0px 25px;
            margin: 0px;
          }
            .basket-product-item .list-view-item-name {
              font-size: 18px;
              font-weight: 600;
              margin: 0px;
            }
              .basket-product-item .list-view-item-name a:hover,
              .basket-product-item .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                color: #e01f26;
              }

            .basket-product-item .product-view-item-params {
              font-style: normal;
              text-align: left;
              margin-top: 0px;
            }

        .basket-product-item > div + div {
          justify-content: flex-end;
          align-items: center;
          flex: 1 0 0;
          padding: 25px 0px 25px 20px;
        }
          .basket-product-item > div + div > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin: -15px 0px 0px -20px;
          }
            .basket-product-item > div + div > div:first-child > div {
              padding: 15px 0px 0px 20px;
            }

            .basket-product-item .basket-product-price {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              min-width: 28%;
              white-space: nowrap;
            }
              .basket-product-item .basket-product-price > span:first-child {
                color: #404040;
                margin-bottom: 4px;
              }
              .basket-product-item .basket-product-price > span:first-child + span {
                color: #1b1e25;
                font-size: 20px;
                font-weight: 500;
              }
              .basket-product-item .basket-product-price > span:first-child + span + span {
                color: #808080;
                text-decoration: line-through;
                margin-top: 4px;
              }

            .basket-product-item .basket-product-amount {
              min-width: 34%;
              height: 48px;
            }
              .basket-product-item .basket-product-amount > span {
                display: none;
              }

              .basket-product-item .product-amount .input-container {
                background: #f0f0f0;
                border: 1px solid #e8e8e8;
              }

            .basket-product-item .basket-product-sum {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              min-width: 38%;
              white-space: nowrap;
              text-align: right;
            }
              .basket-product-item .basket-product-sum > span:first-child {
                color: #1b1e25;
                font-size: 22px;
                font-weight: 500;
                order: 2;
              }
              .basket-product-item .basket-product-sum > span:first-child + span {
                color: #404040;
                margin-bottom: 4px;
                order: 1;
              }
              .basket-product-item .basket-product-sum > span:first-child + span + span {
                color: #808080;
                text-decoration: line-through;
                margin-top: 4px;
                order: 3;
              }
              .basket-product-item .basket-product-sum > span:first-child + span + span + span {
                color: #808080;
                font-size: 12px;
                vertical-align: baseline;
                margin-top: 8px;
                order: 4;
              }
                .basket-product-item .basket-product-sum > span:first-child + span + span + span > span {
                  color: #1b1e25;
                  font-weight: 500;
                  display: inline-block;
                  background: #fbcd56;
                  border-radius: 4px;
                  vertical-align: baseline;
                  padding: 3px 4px 2px;
                }

          .basket-product-item .basket-product-delete {
            align-self: stretch;
            color: transparent;
            font-size: 0px;
            margin: -25px 0px -25px 20px;
          }
            .basket-product-item .basket-product-delete a {
              display: block;
              background: url('/i/cart-icon-delete.svg') no-repeat 50% 50%/18px auto #f2f2f2;
              width: 40px;
              min-width: 40px;
              height: 100%;
              position: relative;
              cursor: pointer;

              -webkit-transition: background-color .3s ease 0s;
              -moz-transition: background-color .3s ease 0s;
              -o-transition: background-color .3s ease 0s;
              transition: background-color .3s ease 0s;
            }
              .basket-product-item .basket-product-delete a:hover {
                background-color: rgba(224, 31, 38, 0.1);
              }


      .basket-form .form-elements {
        padding: 0px 40px 40px;
        margin: 0px auto;
      }
        .basket-form .form-elements .form-row + .form-row {
          margin-top: 30px;
        }
          .basket-form .form-elements .form-row:not(.form-row-checkbox) .form-label {
            font-size: 16px;
            font-weight: 500;
          }


      .basket-sum-total {
        font-size: 16px;
        text-align: right;
        padding: 0px 0px 20px;
      }
        .basket-sum-total div:first-child {
          display: inline-block;
          vertical-align: baseline;
        }

        .basket-sum-total div + div {
          display: inline-block;
          font-size: 28px;
          font-weight: 700;
          white-space: nowrap;
          vertical-align: baseline;
          margin-left: 5px;
        }
          .basket-sum-total div + div span {
            font-size: 20px;
            font-weight: 400;
            vertical-align: baseline;
          }


    .basket-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 25px;
      margin: -10px 0px 0px -10px;
    }
      .basket-right-panel .basket-buttons-container {
        flex-direction: column;
        align-items: flex-start;
      }
      .basket-form .basket-buttons-container {
        flex-grow: 1;
      }

      .basket-buttons-container > div {
        padding: 10px 0px 0px 10px;
      }
        .basket-buttons-container .button-style {
          font-size: 16px;
        }

        .basket-buttons-container .return-button {
          font-size: 18px;
          border-bottom: 1px dashed #282828;
          line-height: 1.2;
          cursor: pointer;
        }
          .basket-buttons-container .return-button:hover {
            border-color: transparent;
          }

        .basket-buttons-container .goto-first-step-link a {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          font-size: 18px;
          text-decoration: none !important;
        }
          .basket-buttons-container .goto-first-step-link a:hover {
            text-decoration: underline !important;
          }
  /* Content block end */



  /* Contact page */
  .contact-page-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    margin: 0px 0px 0px -45px;
  }
    .contact-page-info > div {
      padding: 0px 0px 25px 45px;
    }
      .contact-page-title {
        font-weight: 700;
        padding: 0px 0px 4px;
      }

      .contact-page-phones > div + div a {
        cursor: text;
        white-space: nowrap;
      }

      .contact-page-emails > div + div a {
        cursor: pointer;
        text-decoration: underline;
        white-space: nowrap;
      }
        .contact-page-emails > div + div a:hover {
          text-decoration: none;
        }


  .contact-page-map {
    padding: 0px 0px 25px;
    position: relative;
  }
    .contact-page-map > div {
      height: 0px;
      padding-top: 45%;
      position: relative;
    }
      .contact-page-map > div:before {
        content: "Загрузка карты...";
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
      }

      .contact-page-map > div > div {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2;
      }
  /* Contact page end */



  /* Footer */
  .footer-partners {
    background: #fff;
    border-top: 1px solid #f2f2f2;
    padding: 15px 0px 20px;
  }
    .footer-partners-slider {
      position: relative;
    }
      .footer-partners-slider:not(.slick-initialized) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
      }

      .footer-partner-slide {
        text-align: center;
      }
        .footer-partners-slider:not(.slick-initialized) .footer-partner-slide {
          width: 12.5%;
          min-width: 12.5%;
        }

        .footer-partner-slide img {
          display: inline-block;
          max-width: 100%;
          margin: 0px auto;
          opacity: 0.5;

          filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'saturate\' values=\'0\'/></filter></svg>#grayscale");
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);

          -webkit-transition: opacity .3s ease-out 0s, filter .3s ease-out 0s, -webkit-filter .3s ease-out 0s;
          -moz-transition: opacity .3s ease-out 0s, filter .3s ease-out 0s, -webkit-filter .3s ease-out 0s;
          -o-transition: opacity .3s ease-out 0s, filter .3s ease-out 0s, -webkit-filter .3s ease-out 0s;
          transition: opacity .3s ease-out 0s, filter .3s ease-out 0s, -webkit-filter .3s ease-out 0s;
        }
          .footer-partner-slide img:hover {
            opacity: 1;
            -webkit-filter: unset;
            filter: unset;
          }

      .footer-partners-slider-arrows {
        height: 0px;
      }
        .footer-partners-slider-arrows div {
          display: block;
          background: no-repeat 50% 50%/12px auto transparent;
          width: 25px;
          position: absolute;
          top: 0px;
          bottom: 0px;
          cursor: pointer;
          opacity: .5;

          -webkit-transition: opacity .3s ease-out 0s;
          -moz-transition: opacity .3s ease-out 0s;
          -o-transition: opacity .3s ease-out 0s;
          transition: opacity .3s ease-out 0s;
        }
          .footer-partners-slider-arrows div:hover {
            opacity: 1;
          }

        .footer-partners-slider-arrows .slick-prev {
          background-image: url('/i/rotator-arrow-left.svg');
          left: -25px;
        }
        .footer-partners-slider-arrows .slick-next {
          background-image: url('/i/rotator-arrow-right.svg');
          right: -25px;
        }


  .footer {
    background: #f6f6f7;
    position: relative;
    z-index: 1;
  }
    .footer-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 35px 0px 45px;
      margin-left: -60px;
    }
      .footer-content > div:first-child {
        width: 25%;
        max-width: 25%;
        padding-left: 60px;
      }
        .footer-logo + div {
          padding: 10px 0px 0px;
        }
          .footer-logo + div > div {
            font-size: 15px;
            padding: 15px 0px 0px;
          }


      .footer-menu {
        flex: 1 1 0px;
      }
        .footer-menu > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: flex-start;
          margin: -6px 0px 0px;
        }
          .footer-menu > ul > li {
            padding: 0px 10px 0px 70px;
          }
            .footer-menu ul > li > div .dropmarker {
              display: none;
            }

            .footer-menu ul > li > div a,
            .footer-menu ul > li > div span {
              display: inline-block;
              color: #555;
              font-size: 13px;
              line-height: 1.2;
              padding: 6px;
            }
            .footer-menu ul > li > div a {
              cursor: pointer;
            }
              .footer-menu ul > li > div a:hover {
                color: #e01f26;
              }

            .footer-menu > ul > li > div a,
            .footer-menu > ul > li > div span {
              color: #000;
              font-size: 15px;
              font-weight: 700;
            }

            .footer-menu > ul > li > ul {
              padding: 6px 0px 0px;
            }


      .footer-contacts {
        padding-left: 60px;
      }
        .footer-title {
          font-size: 15px;
          font-weight: 700;
          line-height: 1.2;
          padding: 0px 0px 18px;
        }

        .footer-contacts > div + div > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          background: no-repeat top 2px left/16px auto transparent;
          line-height: 1.3;
          min-height: 21px;
          padding-left: 32px;
        }
        .footer-contacts > div + div > div + div {
          margin: 20px 0px 0px;
        }

        .footer-phones {
          background-image: url('/i/footer-contact-icon-phone.svg') !important;
        }
          .footer-phones a {
            font-size: 15px;
            font-weight: 700;
            text-decoration: none;
            cursor: pointer;
          }

        .footer-emails {
          background-image: url('/i/footer-contact-icon-email.svg') !important;
        }
          .footer-emails a {
            text-decoration: none;
            cursor: pointer;
          }
            .footer-emails a:hover {
              color: #e01f26;
            }

        .footer-address {
          background-image: url('/i/footer-contact-icon-address.svg') !important;
        }
          .footer-address span {
            font-weight: 700;
          }

          .footer-phones > div + div,
          .footer-emails > div + div,
          .footer-address > div + div {
            padding: 10px 0px 0px;
          }


    .footer-copyrights {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      line-height: 1.3;
      border-top: 1px solid #eee;
      padding: 35px 0px 38px;
    }
      .footer-copyrights * {
        vertical-align: baseline;
      }

      .footer-copyrights > div:first-child {
        color: #888;
      }

      /*.footer-copyrights .link {
        color: #e01f26;
      }*/
  /* Footer end */
/* Structure end */